<script>
	export let skill;
</script>

<style>
	.skill {
    border: 1px solid var(--grey-light);
    width: fit-content;
    padding: 5px 4.5px;
    border-radius: 3px;
    margin: 5px;
    font-size: 0.97em;
    font-weight: 400;
	}
</style>

<div class="skill">{skill}</div>
